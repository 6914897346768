import React, { useEffect, useRef } from "react";

import styles from "./style.module.scss";
import { CurrencyIndicatorsBar, Link, useContentScroll, Footer } from "Components";
import { mdiBitcoin, mdiChevronRight, mdiFaceAgent, mdiHandCoin, mdiIntegratedCircuitChip, mdiInvoiceList, mdiMouseVariant, mdiServerSecurity, mdiTextBoxSearch } from "@mdi/js";
import Icon from "@mdi/react";

import logoImage from "assets/image/logo.png";
import ivipcoinLogoImage from "assets/image/ivipcoin-logo.png";
import ivipbankLogoImage from "assets/image/ivipbank-logo.svg";

const sectionLength = 4;

const footerList: Array<{
    title: string;
    links: Array<{
        href: string;
        title: string;
        target?: "_blank";
    }>;
}>[] = [
    [
        {
            title: "Serviços IVIP",
            links: [
                { href: "https://ivipbank.com/", title: "iVipBank", target: "_blank" },
                { href: "https://ivipcoin.com/", title: "iVipCoin", target: "_blank" },
            ],
        },
    ],
    [
        {
            title: "Produtos",
            links: [
                { href: "#", title: "Spot" },
                { href: "#", title: "Inverse Perpetual" },
                { href: "#", title: "USDT Perpetual" },
                { href: "#", title: "Trocas" },
                { href: "#", title: "Futures" },
                { href: "#", title: "Launchpad" },
                { href: "#", title: "Binance Pay" },
            ],
        },
        {
            title: "Serviços",
            links: [
                { href: "#", title: "Comprar Crypto" },
                { href: "#", title: "Marcados" },
                { href: "#", title: "Tranding Fee" },
                { href: "#", title: "Affiliate Program" },
                { href: "#", title: "Referral Program" },
            ],
        },
    ],
    [
        {
            title: "SUPPORT",
            links: [
                { href: "#", title: "Bybit Learn" },
                { href: "#", title: "Help Center" },
                { href: "#", title: "User Feedback" },
                { href: "#", title: "Submit a request" },
                { href: "#", title: "Trading Rules" },
            ],
        },
        {
            title: "API",
            links: [
                { href: "#", title: "Platform overview" },
                { href: "#", title: "Pricing" },
                { href: "#", title: "Documentation" },
                { href: "#", title: "API login" },
            ],
        },
    ],
    [
        {
            title: "SOBRE NÓS",
            links: [
                { href: "#", title: "Sobre Bybit" },
                { href: "#", title: "Autenticação Check" },
                { href: "#", title: "Carreira" },
                { href: "#", title: "Contactos" },
                { href: "#", title: "Blog" },
            ],
        },
    ],
];

const Home: React.FC = () => {
    const { onScroll, offScroll } = useContentScroll();
    const gradientRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const event = (e: any) => {
            if (!gradientRef.current) {
                return;
            }
            const deg = Math.round((e.top / (e.height - e.clientHeight)) * (80 * (sectionLength - 1)));
            gradientRef.current.style.transform = `rotate(${deg}deg)`;
        };

        onScroll(event);
        return () => offScroll(event);
    }, [onScroll, offScroll]);

    return (
        <>
            <div className={styles.background}>
                <div ref={gradientRef} className={styles.gradient}>
                    <div className={styles.gradientTopRight}></div>
                    <div className={styles.gradientBottomLeft}></div>
                </div>
                <div className={styles.backgroundName}>IVIPEX</div>
            </div>
            <div id="home" className={[styles.page, styles.home].join(" ")}>
                <div className={styles.content}>
                    <p>HOME</p>
                    <h1>
                        Explore o mundo das <span>CRIPTOS</span> com a mais recente e melhor plataforma do mercado!
                    </h1>
                    <p>Plataforma inovadora para investidores em criptomoedas, com interface intuitiva e recursos para explorar, monitorar e realizar transações seguras.</p>
                    <Link href="https://app.ivipex.com/login" className={styles.btm}>
                        Explorar <Icon path={mdiChevronRight} />
                    </Link>
                </div>
                <div className={styles.whats_new}>
                    <Link href="/whats_new">
                        <div className={styles.title}>
                            <p>Veja o que há de NOVO</p>
                            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 9L9 1M9 1H2.5M9 1V7.22222"></path>
                            </svg>
                        </div>
                    </Link>
                    <div className={styles.links}>
                        <Link href="/whats_new">
                            <div>
                                <h6>Registro pré-cadastro</h6>
                                <p>{new Date(2024, 9, 8).toLocaleDateString()}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            <CurrencyIndicatorsBar
                style={{
                    background: "rgba(0, 0, 0, .1)",
                    borderRadius: "25px",
                    margin: "40px 15px",
                    border: "1px solid rgba(0, 0, 0, .2)",
                }}
            />
            <div id="about" className={[styles.page, styles.about].join(" ")}>
                <div className={styles.content}>
                    <p>SOBRE</p>
                    <h1>
                        Opere com <span>segurança</span> e <span>confiança</span> em nossa plataforma simples de usar!
                    </h1>
                </div>
                <div className={styles.box}>
                    <p>
                        Esta plataforma inovadora oferece uma experiência completa para investidores e entusiastas de criptomoedas. Com uma interface intuitiva e recursos avançados, os usuários podem
                        explorar diversas criptomoedas, monitorar suas performances em tempo real e realizar transações com segurança.
                    </p>
                    <p>Os principais destaques incluem:</p>
                    <ul>
                        <li>
                            <b>Negociação Fácil</b>: Acesso rápido a uma ampla gama de criptomoedas com ferramentas de negociação eficientes.
                        </li>
                        <li>
                            <b>Segurança Avançada</b>: Proteção robusta para garantir que seus ativos digitais estejam sempre seguros.
                        </li>
                        <li>
                            <b>Análises em Tempo Real</b>: Gráficos e dados atualizados para ajudar os usuários a tomar decisões informadas.
                        </li>
                        <li>
                            <b>Suporte ao Cliente</b>: Equipe dedicada pronta para auxiliar em qualquer dúvida ou problema.
                        </li>
                        <li>
                            <b>Educação e Recursos</b>: Material educativo disponível para iniciantes e investidores experientes, promovendo uma melhor compreensão do mercado de criptomoedas.
                        </li>
                    </ul>
                    <p>Descubra a maneira mais eficiente e segura de investir em criptomoedas com esta plataforma de ponta, que une tecnologia avançada e uma comunidade ativa de usuários.</p>
                </div>
            </div>
            <div id="main_advantages" className={[styles.page, styles.main_advantages].join(" ")}>
                <div className={styles.content}>
                    <p>PRINCIPAIS VANTAGENS</p>
                    <h1>
                        Descubra os benefícios exclusivos da <span>iVip</span>Exchange e aproveite
                    </h1>
                    <Link href="https://app.ivipex.com/login" className={styles.btm}>
                        Explorar <Icon path={mdiChevronRight} />
                    </Link>
                </div>
                <div className={styles.cards}>
                    <div className={styles.card} style={{ "--primary": "#E91E63" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiServerSecurity} />
                            <p>Segurança de Ponta</p>
                        </div>
                        <div className={styles.body}>
                            <p>Seus ativos protegidos com as mais avançadas tecnologias</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#4caf50" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiMouseVariant} />
                            <p>Facilidade de Uso</p>
                        </div>
                        <div className={styles.body}>
                            <p>Interface amigável para uma experiência rápida e simples</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#ff9800" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiBitcoin} />
                            <p>Variedade de Criptomoedas</p>
                        </div>
                        <div className={styles.body}>
                            <p>Ampla seleção para diversificar seus investimentos</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#2196F3" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiIntegratedCircuitChip} />
                            <p>Acessibilidade bancária</p>
                        </div>
                        <div className={styles.body}>
                            <p>Cartão de crédito e débito para os usuários brasileiros</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#f44336" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiInvoiceList} />
                            <p>Taxas</p>
                        </div>
                        <div className={styles.body}>
                            <p>Taxa 0% em transações e negociações</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#673ab7" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiHandCoin} />
                            <p>Empréstimos</p>
                        </div>
                        <div className={styles.body}>
                            <p>Empréstimo a 0.1% ao mês para investidores</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#00bcd4" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiTextBoxSearch} />
                            <p>Análises em Tempo Real</p>
                        </div>
                        <div className={styles.body}>
                            <p>Dados precisos para decisões informadas</p>
                        </div>
                    </div>
                    <div className={styles.card} style={{ "--primary": "#ff5722" } as React.CSSProperties}>
                        <div className={styles.title}>
                            <Icon path={mdiFaceAgent} />
                            <p>Suporte Dedicado</p>
                        </div>
                        <div className={styles.body}>
                            <p>Atendimento ágil e eficiente sempre que precisar</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ivip_community" className={[styles.page, styles.ivip_community].join(" ")}>
                <div className={styles.content}>
                    <p>COMUNIDADE IVIP</p>
                    <h1>
                        <span>IVIPEX</span> é uma das plataformas que compõe a comunidade <span>IVIP</span>
                    </h1>
                    <p>
                        Como parte da comunidade IVIP, a IVIPEX oferece um ambiente seguro e eficiente para negociação de criptomoedas, alinhado aos princípios de inovação e excelência. A plataforma
                        foi projetada para fornecer aos usuários as melhores ferramentas e recursos para explorar o universo das criptomoedas com confiança e facilidade.
                    </p>
                </div>
                <div className={styles.profile_card} style={{ "--primary": "#673ab7" } as React.CSSProperties}>
                    <div className={styles.picture}>
                        <img src={ivipbankLogoImage} />
                    </div>
                    <div className={styles.info}>
                        <h3>iVipBank</h3>
                        <p>Um banco digital brasileiro que oferece soluções financeiras modernas e seguras para facilitar sua vida.</p>
                        <Link href="https://ivipbank.com/" target="_blank">
                            Saiba mais
                        </Link>
                    </div>
                </div>
                <div className={styles.profile_card} style={{ "--primary": "#ff6d00" } as React.CSSProperties}>
                    <div className={styles.picture}>
                        <img src={ivipcoinLogoImage} />
                    </div>
                    <div className={styles.info}>
                        <h3>iVipCoin</h3>
                        <p>Uma plataforma onde o trader poderá ter acesso a todo conhecimento, desde o básico ao avançado, envolvendo mercado financeiro.</p>
                        <Link href="https://ivipcoin.com/" target="_blank">
                            Saiba mais
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Home;
